import { h } from 'preact';
import { connect } from 'react-redux';

import SetLink from '../components/SetLink';
import { tryRegister, tryLogin, tryLogout } from '../actions/login';

// Create statically to avoid recreating bindings on every update and losing focus

const Login = ({ form = {}, onTryRegister, onTryLogin, onTryLogout }) => {
	const doItText = form.registerMode ? 'Register' : 'Log In';
	const modeSwitchText = form.registerMode ? 'Already have an account?' : 'Create an account';
	const doItAction = form.registerMode ? (
		() => onTryRegister(form.email, form.password)
	) : (
		() => onTryLogin(form.email, form.password)
	);

	return (
		<div key="login-action">
			<input type="button" value={doItText} onClick={doItAction} />
			<SetLink value={!form.registerMode} path={['login', 'form', 'registerMode']}>{modeSwitchText}</SetLink>
		</div>
	);
};


const mapStateToProps = state => ({
	form: state.login.form,
});

const mapDispatchToProps = dispatch => ({
	onTryRegister: (email, password) => dispatch(tryRegister(email, password, dispatch)),
	onTryLogin: (email, password) => dispatch(tryLogin(email, password, dispatch)),
	onTryLogout: () => dispatch(tryLogout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
