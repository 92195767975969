import { h } from 'preact';
import { Provider } from 'react-redux';
import { Router, Route } from 'preact-router';

import { store, syncedHistory } from '../store';

import MainPage from './MainPage';



export default () => (
	<Provider store={store}>
		<Router history={syncedHistory}>
			<Route path='/' component={MainPage} default />
			<Route path='/login' component={MainPage} />
			<Route path='/pings' component={MainPage} />
			<Route path='/secrets' component={MainPage} />
			<Route path='/logout' component={MainPage} />
		</Router>
	</Provider>
);
