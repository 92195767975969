const findOctant = (x0, y0, x1, y1) => {
	const dx = x1 - x0;
	const dy = y1 - y0;
	if (dy > 0) {
		if (dx > 0) {
			if (dx > dy) return 0;
			return 1;
		}
		if (-dx < dy) return 2;
		return 3;
	}
	if (dx < 0) {
		if (dx < dy) return 4;
		return 5;
	}
	if (dx < -dy) return 6;
	return 7;
};

const bresenham2 = (x0, y0, x1, y1) => {
	// do nothing if not in quadrant 0
	if (x0 > x1 || y0 > y1) return [];
	const dx = x1 - x0;
	const dy = y1 - y0;
	// do nothing if not in octant 0
	if (dy > dx) return [];

	let D = (2 * dy) - dx;
	let y = y0;

	const points = [];

	for (let x = x0; x <= x1; x++) {
		points.push([x, y]);
		if (D > 0) {
			y += 1;
			D -= (2 * dx);
		}
		D += (2 * dy);
	}
	return points;
};

const bresenham = (x0, y0, x1, y1) => {
	switch (findOctant(x0, y0, x1, y1)) {
	case 0:
		return bresenham2(x0, y0, x1, y1).map(([x, y]) => [x, y]);
	case 1:
		return bresenham2(y0, x0, y1, x1).map(([x, y]) => [y, x]);
	case 2:
		return bresenham2(y0, -x0, y1, -x1).map(([x, y]) => [-y, x]);
	case 3:
		return bresenham2(-x0, y0, -x1, y1).map(([x, y]) => [-x, y]);
	case 4:
		return bresenham2(-x0, -y0, -x1, -y1).map(([x, y]) => [-x, -y]);
	case 5:
		return bresenham2(-y0, -x0, -y1, -x1).map(([x, y]) => [-y, -x]);
	case 6:
		return bresenham2(-y0, x0, -y1, x1).map(([x, y]) => [y, -x]);
	case 7:
		return bresenham2(x0, -y0, x1, -y1).map(([x, y]) => [x, -y]);
	default:
		return [];
	}
};



export default ({ canvas, brush, from, to }) => {
	// get list of [row, col] to fill in & apply to canvas
	bresenham(from.col, from.row, to.col, to.row)
		.forEach(([col, row]) => {
			canvas[row][col] = brush;
		});
	// No more return value!
};
