import { h, Fragment } from 'preact';
import { useState, useEffect } from 'preact/hooks';
import { memo } from 'preact/compat';
import { connect } from 'react-redux';


import { ColourSelector } from '../util/palette';

import { writePath } from '../actions/generic';
import { post } from '../actions/post';
import paint from '../reducers/composer/paint';

import { MIN_LINES, MAX_LINES, LINE_LENGTH } from '../constants/numbers';
import { newTxtLine, newFgLine, newBgLine } from '../constants/honk';


const dragSizerStartHandler = (event, state, setState) => {
	const startLineCount = state.draft.txt.length;
	const startY = event.clientY;

	const typicalChar = document.querySelector('#compose-honk .honk-editor span');
	const lineHeightPx = typicalChar.getBoundingClientRect().height;

	// new line count = original line count + (dist between drag start point and current mouse pos / line height)
	// ...clamped between 1 and MAX_LINES
	const nextLineCount = e => {
		let nlc = startLineCount + (e.clientY - startY) / lineHeightPx;
		nlc = Math.max(MIN_LINES, nlc);
		nlc = Math.min(MAX_LINES, nlc);
		nlc = Math.floor(nlc);
		return nlc;
	};

	// Tell component state how big the honk will be once resized
	const moveHandler = e => {
		setState({
			...state,
			tempLineCount: nextLineCount(e)
		});
	};

	const endHandler = e => {
		document.removeEventListener('mousemove', moveHandler);
		document.removeEventListener('mouseup', endHandler);
		const newLineCount = nextLineCount(e);

		let { txt, fg, bg } = state.draft;

		if (txt.length < newLineCount) {
			txt = txt.slice();
			fg = fg.slice();
			bg = bg.slice();
			while (txt.length < newLineCount) {
				txt.push(newTxtLine.slice());
				fg.push(newFgLine.slice());
				bg.push(newBgLine.slice());
			}
		} else {
			txt = txt.slice(0, newLineCount);
			fg = bg.slice(0, newLineCount);
			bg = bg.slice(0, newLineCount);
		}

		// Done dragging! Finally update the actual draft.
		setState({
			...state,
			draft: { ...state.draft, txt, fg, bg },
			tempLineCount: null,
		});
	};

	document.addEventListener('mousemove', moveHandler);
	document.addEventListener('mouseup', endHandler);
};

const addLinesTo = (draft, newLength) => {
	while (draft.txt.length < newLength) {
		draft.txt.push(newTxtLine.slice());
		draft.bg.push(newBgLine.slice());
		draft.fg.push(newFgLine.slice());
	}
};


// Handle typing, navigation, modifier press
const handleKeyDown = (event, state, setState) => {
	event.stopPropagation();
	event.preventDefault();

	if (event.key === 'Control') {
		setState({ ...state, lineMode: false });
		return;
	}

	if (state.mode === 'type') {
		let { row, col } = state.cursor;
		const { key } = event;
		let cursorChange = 0;
		let contentChange = 0;

		if (key && key.length === 1) {
			// Printable character = type it
			state.draft.txt[row][col] = event.key;

			if (col >= LINE_LENGTH - 1) {
				// off the edge? new line if you can
				if (row < MAX_LINES - 1) {
					addLinesTo(state.draft, row);
					col = state.textStart.col;
					row += 1;
				}
			} else {
				col += 1; // just advance
			}
			cursorChange = 1;
			contentChange = 1;
		} else if (key === 'Backspace') {
			// find the row/col to delete
			col -= 1;
			if (col < 0) {
				if (row > 0) {
					row -= 1;
					col += 80;
				} else {
					col = 0;
				}
			}
			// update content and cursor in-place
			state.draft.txt[row][col] = ' ';
			cursorChange = 1;
			contentChange = 1;
		} else if (key === 'Enter') {
			row += 1;
			if (row >= MAX_LINES) {
				row -= 1;
			} else {
				col = state.textStart.col;
			}

			addLinesTo(state.draft, row);
			cursorChange = 1;
			contentChange = 1;
		} else if (key.match(/^Arrow/)) {
			let rowMove = 0;
			let colMove = 0;
			if (key === 'ArrowUp') rowMove = -1;
			if (key === 'ArrowDown') rowMove = 1;
			if (key === 'ArrowLeft') colMove = -1;
			if (key === 'ArrowRight') colMove = 1;

			col += colMove;
			row += rowMove;

			if (col < 0) {
				if (row > 0) {
					col += LINE_LENGTH;
					row -= 1;
				} else {
					col = 0;
				}
			}
			if (col >= LINE_LENGTH) {
				if (row < state.draft.txt.length - 1) {
					col = 0;
					row += 1;
				} else {
					col -= 1;
				}
			}
			row = Math.max(row, 0);
			row = Math.min(row, state.draft.txt.length - 1);
			cursorChange = 1;
		}


		state.cursor.row = row;
		state.cursor.col = col;
		state.contentUpdates += contentChange;
		state.cursorUpdates += cursorChange;
		setState({ ...state });
	}
	return false;
};


// Handle modifier release
const handleKeyUp = (event, state, setState) => {
	if (event.key === 'Control') setState({ ...state, lineMode: true });
};


const textInput = (event, state, setState) => {
	if (!state.textFocus) return;

	// TODO event.isComposing implies user is typing something through IME... how to display/overwrite interim value?
	let { row, col } = state.cursor;
	const toInsert = event.data;
	let cursorChange = 0;
	let contentChange = 0;

	Array.prototype.forEach.call(toInsert, char => {
		// TODO Is this correct? Across different OSs?
		if (char === '\n') {
			row += 1;
			if (row >= MAX_LINES) {
				row -= 1;
			} else {
				col = state.textStart.col;
			}

			addLinesTo(state.draft, row);
			cursorChange = 1;
			contentChange = 1;
			return;
		}

		// Printable character = type it
		state.draft.txt[row][col] = char;

		if (col >= LINE_LENGTH - 1) {
			// off the edge? new line if you can
			if (row < MAX_LINES - 1) {
				addLinesTo(state.draft, row);
				col = state.textStart.col;
				row += 1;
			}
		} else {
			col += 1; // just advance
		}
		cursorChange = 1;
		contentChange = 1;
	});

	setState({ ...state, cursorChange, contentChange });
};



const getRC = el => ({
	row: Number.parseInt(el.getAttribute('row')),
	col: Number.parseInt(el.getAttribute('col')),
});

const decodeButtons = (bin) => {
	const buttons = {};
	if (bin >= 16) {
		buttons.forward = true;
		bin -= 16;
	}
	if (bin >= 8) {
		buttons.back = true;
		bin -= 8;
	}
	if (bin >= 4) {
		buttons.middle = true;
		bin -= 4;
	}
	if (bin >= 2) {
		buttons.right = true;
		bin -= 2;
	}
	if (bin >= 1) {
		buttons.left = true;
	}
	return buttons;
};

const handleMouseDown = (event, state, setState) => {
	const buttons = decodeButtons(event.buttons);
	if (!buttons.left) return;

	const coords = getRC(event.target);

	if (state.mode === 'paint') {
		const { paintMode } = state;
		// Update the canvas in-place
		paint({
			canvas: state.draft[paintMode],
			brush: paintMode === 'txt' ? state.paintChar : state.paintColour,
			from: coords,
			to: coords,
		});
		// ...and bump the modification count to provoke a redraw + record the mouse state
		setState({
			...state,
			mouseDown: true,
			lastClick: coords,
			lastMove: coords,
			contentUpdates: state.contentUpdates + 1,
		});
	} else {
		// Start a new selection & move the line-start
		setState({
			...state,
			buttons,
			cursor: coords,
			textStart: coords,
		});
	}
};




const handleMouseMove = (event, state, setState) => {
	const buttons = decodeButtons(event.buttons);
	if (!buttons.left) return;

	const coords = getRC(event.target);

	if (state.mode === 'paint' && coords.row !== undefined && coords.col !== undefined) {
		const { paintMode } = state;
		// Update the canvas in-place
		paint({
			canvas: state.draft[paintMode],
			brush: paintMode === 'txt' ? state.paintChar : state.paintColour,
			from: state.lastMove || coords,
			to: coords,
		});
		// ...and bump the modification count to provoke a redraw + record the mouse state
		setState({
			...state,
			lastMove: coords,
			buttons,
			contentUpdates: state.contentUpdates + 1,
		});
	} else {
		// Text mode, just move the cursor
		setState({
			...state,
			cursor: coords,
			buttons
		});
	}
};

// end drawing
const handleMouseUp = (event, state, setState) => {
	const buttons = decodeButtons(event.buttons);
	// Set mouse down to false
	setState({
		...state,
		lastMove: null,
		buttons
	});
};

// continue drawing after overshooting the edge, but not from last coords!
const handleMouseLeave = (event, state, setState) => {
	setState({ ...state, lastMove: null, });
};

// placeholder, nothing to do here yet
const handleMouseEnter = (event, state, setState) => false;



const CharEntry = memo(({ char: c, enterFn }) => {
	const line = `${c}${c}${c}${c}\n`;
	const getChar = e => e.key && e.key.length === 1 && enterFn(e.key);
	return (
		<div>
			<pre>{line}{line}{line}{line}</pre>
			<input type="text" size="1" value={c} onKeyUp={getChar} />
		</div>
	);
});


const DummyLines = memo(({ length }) => {
	const lines = [];
	for (let i = 0; i < length; i++) lines.push(<div key={'dummy' + i}>&nbsp;</div>);
	return lines;
});


const RunLine = memo(({ prefix, colours, txt }) => {
	let startSpan = 0;
	let prevColour = colours[0];
	const spans = [];
	const last = colours.length - 1;
	Array.prototype.forEach.call(colours, (currentColour, col) => {
		if (col === last || prevColour !== currentColour) {
			const endSlice = col !== last ? col : col + 1;
			spans.push(<span className={`${prefix}x${prevColour.toString(16)}`}>{txt.slice(startSpan, endSlice).join('')}</span>);
			startSpan = col;
			prevColour = currentColour;
		}
	});
	return <div>{spans}</div>;
});

const BgLines = memo(({ bg, lineCount, updates }) => {
	return (
		<div className="bg">
			{bg.slice(0, lineCount).map((line, row) => <RunLine key={row} prefix="b" colours={line} txt={newTxtLine} updates={updates} />)}
		</div>
	);
});

const FgLines = memo(({ txt, fg, lineCount, updates }) => (
	<div className="fg">
		{fg.slice(0, lineCount).map((line, row) => <RunLine key={row} prefix="f" colours={line} txt={txt[row]} updates={updates} />)}
	</div>
));

const InputGrid = memo(({ width, height, cursor, showCursor }) => {
	const rows = [];
	for (let row = 0; row < height; row++) {
		const cols = [];
		for (let col = 0; col < width; col++) {
			const className = (showCursor && cursor.row === row && cursor.col === col) ? 'cursor' : '';
			cols.push(<span key={`c${col}`} row={row} col={col} className={className}> </span>);
		}
		rows.push(<div key={`r${row}`}>{cols}</div>);
	}
	return <div className="paintgrid">{rows}</div>;
});

const CursorGrid = () => <div />;


const Composer = ({ composerState, onSubmit, hideComposer }) => {
	// Store the draft state locally and skip the redux update loop while actively drawing/typing
	const [state, setState] = useState({
		...composerState,
		cursor: { row: 0, col: 0 },
		textStart: { row: 0, col: 0 },
		buttons: {},
		lastClick: null,
		lastMove: null,
		lineMode: false,
		mode: 'paint',
		paintMode: 'bg',
		contentUpdates: 0,
		cursorUpdates: 0,
	});

	// Handle key up/down only while open
	/* useEffect(() => {
		const downHandler = event => handleKeyDown(event, state, setState);
		const upHandler = event => handleKeyUp(event, state, setState);
		document.addEventListener('keydown', downHandler);
		document.addEventListener('keyup', upHandler);
		return () => {
			document.removeEventListener('keydown', downHandler);
			document.removeEventListener('keyup', upHandler);
		};
	}, [state, setState]);
*/

	const lineCount = state.tempLineCount || state.draft.txt.length;

	return (
		<div className="modal" onClick={hideComposer} onMouseUp={e => handleMouseUp(e, state, setState)}>
			<div id="compose-honk" onClick={e => e.stopPropagation()}>
				<div>{lineCount} line{lineCount === 1 ? '' : 's'}</div>
				<div className="editor-box">
					<pre
						tabIndex="0"
						className={`honk-editor editor-mode-${state.mode}`}
						onSelectStart={state.mode === 'type' ? null : e => e.preventDefault()}
						onMouseDown={e => handleMouseDown(e, state, setState)}
						onMouseMove={e => handleMouseMove(e, state, setState)}
						onMouseLeave={e => handleMouseLeave(e, state, setState)}
						onMouseEnter={e => handleMouseEnter(e, state, setState)}
					>
						{/* <HonkLines {...state.draft} length={state.tempLineCount} /> */}
						<BgLines bg={state.draft.bg} lineCount={lineCount} updates={state.contentUpdates} />
						<DummyLines length={lineCount - state.draft.txt.length} />
						<FgLines fg={state.draft.fg} txt={state.draft.txt} lineCount={lineCount} updates={state.contentUpdates} />
						<CursorGrid height={lineCount} width={LINE_LENGTH} cursor={state.cursor} selection={state.selection} mode={state.mode} textFocus={state.textFocus} />
						<InputGrid height={lineCount} width={LINE_LENGTH} cursor={state.cursor} updates={state.cursorUpdates} />
						<input
							type="textarea"
							className="text-catcher"
							onFocus={() => setState({ ...state, textFocus: true })}
							onBlur={() => setState({ ...state, textFocus: false })}
							onInput={e => textInput(e, state, setState)}
						/>
					</pre>
					<div
						className="honk-sizer"
						onMouseDown={event => dragSizerStartHandler(event, state, setState)}
						onSelectStart={event => event.preventDefault()}
					>
						&nbsp;↕&nbsp;
					</div>
				</div>
				<div>
					<button type="button" onClick={() => onSubmit(state.draft)}>
						POSTPOSTPOST
					</button>
				</div>
				<div>
					<button onClick={() => setState({ ...state, mode: 'type' })}>Type</button>
					<button onClick={() => setState({ ...state, mode: 'paint', paintMode: 'bg' })}>Paint BG</button>
					<button onClick={() => setState({ ...state, mode: 'paint', paintMode: 'fg' })}>Paint FG</button>
					<button onClick={() => setState({ ...state, mode: 'paint', paintMode: 'txt' })}>Paint Char</button>
				</div>
				<div className="bg">
					<span className={`colour-picker-demo x${state.paintColour}`} />
				</div>

				<ColourSelector selected={state.paintColour} setColourFn={paintColour => setState({ ...state, paintColour })} />
				<CharEntry char={state.paintChar} enterFn={paintChar => setState({ ...state, paintChar })} />
			</div>
		</div>
	);
};

const mapStateToProps = state => ({
	composerState: state.composer,
});

const mapDispatchToProps = dispatch => ({
	onSubmit: draft => dispatch(post(dispatch, draft)),
	hideComposer: () => dispatch(writePath(['modal'], null)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Composer);
