import { POST_CREATED, STREAM_APPEND, STREAM_PREPEND, STREAM_LOADING_TOP, STREAM_LOADING_BOTTOM } from '../constants/actionTypes';

const initialState = {
	loadingTop: false,
	loadingBottom: false,
	posts: [],
};

export default (state = initialState, action) => {
	switch (action.type) {
	case POST_CREATED:
		return state.slice().unshift(action.post);
	case STREAM_APPEND:
		return {
			...state,
			posts: state.posts.concat(action.posts),
			loadingBottom: false,
		};
	case STREAM_PREPEND:
		return {
			...state,
			posts: action.posts.concat(state.posts),
			loadingTop: false,
		};
	case STREAM_LOADING_TOP:
		return {
			...state,
			loadingTop: true,
		};
	case STREAM_LOADING_BOTTOM:
		return {
			...state,
			loadingBottom: true,
		};
	default:
		return state;
	}
};
