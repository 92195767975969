import { newTxtLine, newFgLine, newBgLine } from '../constants/honk';

// This is the stuff we want to remember between composer opens, the rest is transient in Composer.jsx
const initialState = {
	draft: {
		txt: [newTxtLine.slice()],
		bg: [newBgLine.slice()],
		fg: [newFgLine.slice()],
		meta: '',
	},
	paintColour: 6,
	paintChar: 'X',
	mode: 'paint',
};

export default (state = initialState, action) => {
	switch (action.type) {
	default:
		return state;
	}
};
