const getPath = (root, path = []) => (
	path.reduce((obj, nextPath) => (obj && obj[nextPath]) || null, root)
);

const putPath = (root, path = [], data) => {
	console.log('initial', root);
	if (!path.length) return root;
	const newObj = { ...root };
	let cursor = newObj;

	path.forEach((nextPath, index) => {
		// have we reached the end of the path? Set the value there
		if (index + 1 === path.length) {
			cursor[nextPath] = data;
			return;
		}
		// The path continues but there's nothing there? Make it
		if (!cursor[nextPath] || typeof cursor[nextPath] !== 'object') {
			cursor[nextPath] = {};
		}
		// Continue with copy, not assignment (so we're not mutating original object)
		cursor[nextPath] = { ...cursor[nextPath] };
		cursor = cursor[nextPath];
	});
	console.log('result', newObj);
	return newObj;
};

export { getPath, putPath };
