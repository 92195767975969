import { h } from 'preact';
import { connect } from 'react-redux';

import SetLink from '../components/SetLink';

const NavThing = () => (
	<div className="things">
		<div className="thing new-honk">
			<SetTab name="honk" path={['modal']} value="composer" icon="~" />
		</div>
		<div className="thing nav">
			<NavTab url="stream" name="stream" icon="§" />
			<NavTab url="pings" name="pings" icon="¶" />
			<NavTab url="secrets" name="secrets" icon="¿" />
		</div>
		<div className="thing user-info">
			<SetTab name="log in" path={['modal']} value="login" icon="L" />
			{/*
				<img className="avatar" src="/img/henguin.jpg" />
				<div className="username">&gt;vonrosceau</div>
			*/}
			<NavTab url="settings" name="settings" icon="§" />
			<NavTab url="weirdos" name="weirdos" icon="§" />
			<NavTab url="logout" name="log out" icon="§" />
		</div>
	</div>
);

const NavTab = ({ url, name, icon }) => (
	<a className={`nav-tab ${url}`} href={`${url}`}>
		{name}<span className="tab-icon">{icon}</span>
	</a>
);

const SetTab = ({ path, value, name, icon }) => (
	<SetLink className={`nav-tab ${name}`} path={path} value={value}>
		{name}<span className="tab-icon">{icon}</span>
	</SetLink>
);

const mapStateToProps = state => ({
	/* stream: state.stream, */
});

const mapDispatchToProps = dispatch => ({
	/* fetchStreamFn: () => dispatch(fetchStream(dispatch)), */
});

export default connect(mapStateToProps, mapDispatchToProps)(NavThing);
