import { h } from 'preact';
import { connect } from 'react-redux';

import { writePath } from '../actions/generic';


const SetLink = ({ path, value, ...props }) => {
	const mapDispatchToProps = dispatch => ({
		onClick: () => dispatch(writePath(path, value)),
	});

	const WrappedLink = connect(() => ({}), mapDispatchToProps)(
		stuff => <a href="#" {...stuff} />,
	);

	return <WrappedLink {...props} />;
};

export default SetLink;
