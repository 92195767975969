/* global localStorage */
// implement login stuff in serverio
import { register, login, verify } from '../lib/serverio';
import { LOGIN_ATTEMPT, REGISTER_ATTEMPT, VERIFY_ATTEMPT, LOGOUT, LOGIN_PENDING } from '../constants/actionTypes';

const tryVerify = (dispatch) => {
	verify()
	.then((response) => {
		if (response.user) {
			dispatch({
				type: VERIFY_ATTEMPT,
				success: true,
				user: response.user,
			});
		} else {
			dispatch({
				type: VERIFY_ATTEMPT,
				success: false,
			});
		}
	});

	dispatch({
		type: VERIFY_ATTEMPT,
		success: false,
	});

	return {
		type: LOGIN_PENDING,
	};
};

const tryRegister = (email, password, dispatch) => {
	register({ email, password })
	.then((response) => {
		if (response.access_token) {
			localStorage.setItem('jwt', response.access_token);
			dispatch({
				type: REGISTER_ATTEMPT,
				success: true,
				user: response.user,
			});
		} else {
			dispatch({
				type: REGISTER_ATTEMPT,
				success: false,
			});
		}
	});

	return {
		type: LOGIN_PENDING,
	};
};

const tryLogin = (email, password, dispatch) => {
	login({ email, password })
	.then((response) => {
		if (response.access_token) {
			localStorage.setItem('jwt', response.access_token);
			dispatch({
				type: LOGIN_ATTEMPT,
				success: true,
				user: response.user,
			});
		} else {
			dispatch({
				type: LOGIN_ATTEMPT,
				success: false,
			});
		}
	});

	return {
		type: LOGIN_PENDING,
	};
};

const doLogout = () => {
	localStorage.setItem('jwt', null);
	return {
		type: LOGOUT,
		success: true,
		reason: `buddy they won't even let ME fuck the logout button`,
	};
};

export { tryRegister, tryLogin, tryVerify, doLogout };
