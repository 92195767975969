/* eslint-disable import/prefer-default-export */
import { WRITE_PATH } from '../constants/actionTypes';

const writePath = (path, value) => ({
	type: WRITE_PATH,
	value,
	path,
});

export { writePath };
