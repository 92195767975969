import { get, postJson } from './ajax';

const makePost = draft => (
	postJson('/api/post', draft)
);

const getStream = () => (
	get('/api/stream')
);

const register = deets => (
	postJson('/api/register', deets)
);

const login = deets => (
	postJson('/api/login', deets)
);

const verify = () => (
	get('/api/verify')
);

export { makePost, getStream, register, login, verify };
