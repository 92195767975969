import { LINE_LENGTH } from './numbers';

const newTxtLine = [];
newTxtLine.length = LINE_LENGTH;
newTxtLine.fill(' ');

const newFgLine = [];
newFgLine.length = LINE_LENGTH;
newFgLine.fill('7');

const newBgLine = [];
newBgLine.length = LINE_LENGTH;
newBgLine.fill('0');

export { newTxtLine, newFgLine, newBgLine };
