import { h } from 'preact';
import { connect } from 'react-redux';

import { fetchStream } from '../actions/stream';

import Honk from './Honk';

const Stream = ({ stream, fetchStreamFn }) => (
	<div>
		<input type='button' onClick={fetchStreamFn} value='Fetch stream' />
		{ stream.posts.map(post => <Honk post={post} />) }
	</div>
);

const mapStateToProps = state => ({
	stream: state.stream,
});

const mapDispatchToProps = dispatch => ({
	fetchStreamFn: () => dispatch(fetchStream(dispatch)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Stream);
