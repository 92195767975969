import { POST_CREATED, POST_SENT } from '../constants/actionTypes';
import { makePost } from '../lib/serverio';

const post = (dispatch, draft) => {
	makePost({
		...draft,
	})
	.then(postJson =>
		dispatch({
			type: POST_CREATED,
			post: postJson,
		}),
	);

	return {
		type: POST_SENT,
	};
};

export { post };
