import { getStream } from '../lib/serverio';
import { STREAM_LOADING_BOTTOM, STREAM_APPEND } from '../constants/actionTypes';

const fetchStream = (dispatch) => {
	getStream()
	.then((response) => {
		dispatch({
			type: STREAM_APPEND,
			posts: response.posts,
		});
	});
	return {
		type: STREAM_LOADING_BOTTOM,
	};
};

export { fetchStream };
