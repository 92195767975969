export const POST_CREATED = 'POST_CREATED';
export const POST_SENT = 'POST_SENT';
export const UPDATE_DRAFT = 'UPDATE_DRAFT';
export const STREAM_APPEND = 'STREAM_APPEND';
export const STREAM_PREPEND = 'STREAM_PREPEND';
export const STREAM_LOADING_TOP = 'STREAM_LOADING_TOP';
export const STREAM_LOADING_BOTTOM = 'STREAM_LOADING_BOTTOM';

export const LOGIN_PENDING = 'LOGIN_PENDING';
export const LOGIN_ATTEMPT = 'LOGIN_ATTEMPT';
export const REGISTER_ATTEMPT = 'REGISTER_ATTEMPT';
export const VERIFY_ATTEMPT = 'VERIFY_ATTEMPT';
export const LOGOUT = 'LOGOUT';
export const WRITE_PATH = 'WRITE_PATH';
