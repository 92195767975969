/* global document */
import { h, render } from 'preact';
import App from './containers/App';

import './styles/main.less';

if (process.env.NODE_ENV !== 'production') {
	require('preact/devtools');
}

const root = document.getElementById('app');

render(<App />, root);

