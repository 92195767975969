/* global localStorage */
import { createStore, applyMiddleware, combineReducers } from 'redux';
import { createBrowserHistory } from 'history';

import { syncHistoryWithStore } from 'preact-router-redux/lib';
import routerMiddleware from 'preact-router-redux/lib/middleware';
import { routerReducer } from 'preact-router-redux/lib/reducer';

import stream from './reducers/stream';
import composer from './reducers/composer';
import login from './reducers/login';
import genericReducer from './reducers/generic';


const rootReducer = (state = {}, action) => {
	return {
		routing: routerReducer(state.routing, action),
		stream: stream(state.stream, action),
		composer: composer(state.composer, action),
		login: login(state.login, action),
		...genericReducer(state, action),
	};
};

const browserHistory = createBrowserHistory();
const router = routerMiddleware(browserHistory);

const middleware = [router];
let enhancer;

if (process.env.NODE_ENV !== 'production') {
	const { createLogger } = require('redux-logger');
	middleware.push(createLogger());
	enhancer = (window.__REDUX_DEVTOOLS_EXTENSION__ || (() => undefined))();
}

const createStoreWithMiddleware = applyMiddleware(...middleware)(createStore);

const store = createStoreWithMiddleware(rootReducer, {}, enhancer);



const syncedHistory = syncHistoryWithStore(browserHistory, store);


export {
	store,
	syncedHistory
};
