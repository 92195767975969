/**
 * Understand and accept that this is a big mess of a dumping ground
 * Export what you need only
 */
import { h } from 'preact';
import { memo } from 'preact/compat';


const hex2RGB = hex => {
	const r = Number.parseInt(hex.slice(0, 2), 16);
	const g = Number.parseInt(hex.slice(2, 4), 16);
	const b = Number.parseInt(hex.slice(4, 6), 16);
	return { r, g, b };
};

const RGB2hex = ({ r, g, b }) => {
	const rHex = r.toString(16).padStart(2, '0');
	const gHex = g.toString(16).padStart(2, '0');
	const bHex = b.toString(16).padStart(2, '0');
	return `#${rHex}${gHex}${bHex}`;
};

// returns ranges 0-255
const RGB2HSL = ({ r, g, b }) => {
	const r1 = r / 255;
	const g1 = g / 255;
	const b1 = b / 255;
	const cMax = Math.max(r1, g1, b1);
	const cMin = Math.min(r1, g1, b1);
	const d = cMax - cMin;
	let h;
	if (d === 0) {
		h = 0;
	} else if (cMax === r1) {
		h = 60 * (((g1 - b1) / d) % 6);
	} else if (cMax === g1) {
		h = 60 * (((b1 - r1) / d) + 2);
	} else if (cMax === b1) {
		h = 60 * (((r1 - g1) / d) + 4);
	}
	const l = (cMax + cMin) / 2;
	const s = (d === 0 || d === 1) ? 0 : (d / (1 - Math.abs((2 * l) - 1)));
	return { h: (h / 360) * 255, s: s * 255, l: l * 255 };
};


const colourDiffRGB = ({ r: r1, g: g1, b: b1 }, { r: r2, g: g2, b: b2 }) => {
	const rBar = (r1 + r2) / 2;
	const dRSq = Math.pow(r1 - r2, 2);
	const dGSq = Math.pow(g1 - g2, 2);
	const dBSq = Math.pow(b1 - b2, 2);

	const dCSq = ((2 + (rBar / 256)) * dRSq) + (4 * dGSq) + ((2 + ((255 - rBar) / 256)) * dBSq);
	return Math.sqrt(dCSq);
};


// 8 levels of green, 6 levels of red, 5 levels of blue + 16 pure greys
// ...which means #000 is represented twice OH WELL
// use as transparent tho?
const paletteRGB = [];
for (let i = 0; i <= 255; i++) {
	let r, g, b;
	if (i >= 240) {
		const digit = (i - 240);
		r = digit + (16 * digit);
		g = r;
		b = r;
	} else {
		r = Math.floor(i / 40);
		g = Math.floor((i - (r * 40)) / 5);
		b = i - ((r * 40) + (g * 5));
		r = Math.round((r / 6) * 256);
		g = Math.round((g / 8) * 256);
		b = Math.round((b / 5) * 256);
	}
	paletteRGB.push({ r, g, b });
}
const paletteHexes = paletteRGB.map(RGB2hex);

/** Hex representations of the Pico-8 palette colours */
const pico8Hexes = [
	'FFF1E8',
	'C2C3C7',
	'5F574F',
	'000000',
	'83769C',
	'7E2553',
	'FF004D',
	'FF77A8',
	'FFCCAA',
	'AB5236',
	'FFA300',
	'FFEC27',
	'00E436',
	'008751',
	'1D2B53',
	'29ADFF',
];
const pico8RGB = pico8Hexes.map(hex2RGB);


/** Palette indices of the closest match to each Pico-8 colour */
const pico8Equivs = pico8RGB.map(p8rgb => {
	const closestFromPalette = { colourIndex: null, distance: Number.POSITIVE_INFINITY };
	paletteRGB.forEach((paletteColour, j) => {
		const dist = colourDiffRGB(p8rgb, paletteColour);
		if (dist < closestFromPalette.distance) {
			closestFromPalette.distance = dist;
			closestFromPalette.colourIndex = j;
		}
	});
	return closestFromPalette.colourIndex;
});


/** Assign the rest of the palette to the nearest Pico-8 colour match by hue (unless it's grey or near-grey) */
const subPalettes = [];
paletteRGB.forEach((rgb, index) => {
	// Don't double-include items that are already group-head colours
	if (pico8Equivs.includes(index)) return;

	const hsl = RGB2HSL(rgb);
	const closest = { index: -1, dist: Number.POSITIVE_INFINITY };

	// This threshold can be adjusted to put more off-greys into the grey sets
	if (hsl.s < 0.05) {
		// Pick the grey it matches best using RGB distance
		pico8Equivs.slice(0, 4).forEach((paletteIndex, p8Index) => {
			const p8Colour = paletteRGB[paletteIndex];
			const dist = colourDiffRGB(rgb, p8Colour);
			if (dist < closest.dist) {
				closest.dist = dist;
				closest.index = p8Index;
			}
		});
	} else {
		// Pick the non-grey it matches best based on hue only
		pico8Equivs.slice(4).forEach((paletteIndex, p8Index) => {
			const p8ColourHSL = RGB2HSL(paletteRGB[paletteIndex]);
			const dist = Math.abs(hsl.h - p8ColourHSL.h);
			if (dist < closest.dist) {
				closest.dist = dist;
				closest.index = p8Index + 4;
			}
		});
	}

	if (!subPalettes[closest.index]) subPalettes[closest.index] = [];
	subPalettes[closest.index].push(index);
});

/** Sort each sub-palette by saturation */
subPalettes.forEach((subPalette, i) => {
	subPalettes[i] = subPalette.sort((a, b) => RGB2HSL(a).s < RGB2HSL(b).s);
});


const Swatch = ({ colour, selected, setColourFn }) => (
	<div
		className={`swatch bx${colour.toString(16).padStart(2, '0')}
		${selected ? 'selected' : ''}`}
		onClick={() => setColourFn(colour)}
	/>
);


const ColourSelector = memo(({ selected, setColourFn }) => {
	// construct the "group head colour + expand caret + sub-swatch list" elements
	const swatchGroups = [];
	pico8Equivs.forEach((equivIndex, i) => {
		const subSwatches = subPalettes[i].map((subIndex) => (
			<Swatch key={subIndex} colour={subIndex} selected={selected === subIndex} setColourFn={setColourFn} />
		));

		swatchGroups[i] = (
			<div className="swatch-group">
				<div className="group-colour">
					<Swatch colour={equivIndex} selected={selected === equivIndex} setColourFn={setColourFn} />
				</div>
				<div className="subswatches">
					{subSwatches}
				</div>
			</div>
		);
	});
	return <div className="colour-selector">{swatchGroups}</div>;
});


const RuleList = () => {
	const rules = [];
	paletteHexes.forEach((hexColour, index) => {
		const hexIndex = index.toString(16).padStart(2, '0');
		rules.push(`.fx${hexIndex} { color: ${hexColour} }`);
		rules.push(`.bx${hexIndex} { background-color: ${hexColour} }`);
	});
	return (
		<div>
			{rules.map(rule => <div key={rule.slice(1, 5)}>{rule}</div>)}
		</div>
	);
};


export { ColourSelector, RuleList };
