/* global XMLHttpRequest, localStorage */

const param = (obj = {}) => (
	Object.entries(obj)
	.map(([key, val]) => encodeURI(`${key}=${val}`))
	.join('&')
);

const request = (method, url, payload, contentType) => (
	new Promise((resolve, reject) => {
		const xhr = new XMLHttpRequest();
		xhr.open(method, url);
		if (contentType) {
			xhr.setRequestHeader('Content-Type', contentType);
		}
		xhr.responseType = 'json';

		// add JWT as header if present
		const jwt = localStorage.getItem('jwt');
		if (jwt) {
			xhr.setRequestHeader('Authorization', `Bearer ${jwt}`);
		}

		xhr.onload = () => {
			if (xhr.status >= 200 && xhr.status < 400) {
				resolve(xhr.response);
			} else {
				reject({
					status: xhr.status,
					statusText: xhr.statusText,
				});
			}
		};

		xhr.onerror = () => {
			reject({
				status: xhr.status,
				statusText: xhr.statusText,
			});
		};
		xhr.send(payload);
	})
);

const get = (url, params) => (
	request('GET', `${url}?${param(params)}`)
);

const post = (url, params) => (
	request('POST', url, param(params))
);

const postJson = (url, json) => (
	request('POST', url, JSON.stringify(json), 'application/json')
);

export { get, post, postJson };
