import { h } from 'preact';
import { connect } from 'react-redux';

import Input from '../components/Input';
import LoginAction from './LoginAction';
import { tryVerify, doLogout } from '../actions/login';

// Create statically to avoid recreating bindings on every update and losing focus

const Login = ({show, loginState, onDoLogout, onTryVerify }) => {
	if (!show) return null;
	if (!loginState.verifyAttempted) {
		onTryVerify();
	}

	if (loginState.loggedIn) {
		return (
			<div>
				<p>you're already logged in ya goofus</p>
				<p>ps your email is { loginState.user.email }</p>
				<input type="button" value="Log Out" onClick={() => onDoLogout()} />
			</div>
		);
	}

	return (
		<div key="login-form">
			<Input type="email" key="blorp" name="email" placeholder="Email" statePath={['login', 'form', 'email']} />
			<Input type="password" key="blurp" name="password" placeholder="Password" statePath={['login', 'form', 'password']} />
			<LoginAction />
		</div>
	);
};

const mapStateToProps = state => ({
	loginState: state.login,
	show: state.modal === 'login'
});

const mapDispatchToProps = dispatch => ({
	onDoLogout: () => dispatch(doLogout()),
	onTryVerify: () => dispatch(tryVerify(dispatch)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
