import { h } from 'preact';
import { connect } from 'react-redux';

import { writePath } from '../actions/generic';
import { getPath } from '../util/utils';


const Input = ({ statePath, ...props }) => {
	const mapStateToProps = state => ({
		value: getPath(state, statePath),
	});

	const mapDispatchToProps = dispatch => ({
		onInput: event => dispatch(writePath(statePath, event.target.value)),
	});

	const WrappedInput = connect(mapStateToProps, mapDispatchToProps)(
		stuff => <input {...stuff} />,
	);

	return <WrappedInput key={statePath.join('.')} {...props} />;
};

export default Input;
