import { h } from 'preact';

// TODO this is in Honk and HonkEditor, place in a common location
const fgPool = 'ABCDEFGHIJKLMNOP';
const bgPool = 'abcdefghijklmnop';

const Honk = ({ post }) => {
	const honkSpans = Array.prototype.map.call(post.txt, (char, index) => {
		const bgClass = bgPool[parseInt(post.bg[index], 16)];
		const fgClass = fgPool[parseInt(post.fg[index], 16)];
		return (
			<span className={`${bgClass} ${fgClass}`}>{char}</span>
		);
	});


	const width = 80;
	const honk = [];

	for (let i = 0; i * width < honkSpans.length; i++) {
		const line = [];
		for (let j = 0; ((i * width) + j) < honkSpans.length && j < 80; j++) {
			line.push(honkSpans[(i * width) + j]);
		}
		honk.push(<div>{line}</div>);
	}

	return (
		<pre className='honk'>
			{honk}
		</pre>
	);
};

export default Honk;
