import { h } from 'preact';
import { connect } from 'react-redux';

import Login from './Login';
import Stream from './Stream';
import Composer from './Composer';
import NavThing from './NavThing';


const MainPage = ({ showLogin, showComposer }) => (
	<div>
		<NavThing />
		<div className="app-content">
			<h1>chat... <i>on the internet!</i></h1>
			<Login />
			{showComposer ? <Composer /> : null}
			<Stream />
		</div>
	</div>
);

export default connect(
	state => ({
		showLogin: state.modal === 'login',
		showComposer: state.modal === 'composer',
	}),
)(MainPage);
