import { WRITE_PATH, CREATE_PATH } from '../constants/actionTypes';
import { putPath } from '../util/utils';

export default (state = {}, action) => {
	switch (action.type) {
	case CREATE_PATH:
		return putPath(state, action.path, '');
	case WRITE_PATH:
		return putPath(state, action.path, action.value);
	default:
		return state;
	}
};
