import { LOGIN_ATTEMPT, REGISTER_ATTEMPT, VERIFY_ATTEMPT, LOGOUT, LOGIN_PENDING } from '../constants/actionTypes';

const initialState = {
	verifyAttempted: false,
	loggedIn: false,
	pending: false,
	user: null,
};

export default (state = initialState, action) => {
	switch (action.type) {
	case LOGIN_PENDING:
		return {
			...state,
			pending: true,
		};
	case LOGIN_ATTEMPT:
		return {
			...state,
			loggedIn: action.success,
			pending: false,
		};
	case REGISTER_ATTEMPT:
		return {
			...state,
			loggedIn: action.success,
			pending: false,
		};
	case VERIFY_ATTEMPT:
		return {
			verifyAttempted: true,
			loggedIn: action.success,
			user: action.user,
			pending: false,
		};
	case LOGOUT:
		return {
			...state,
			loggedIn: !action.success,
			pending: false,
		};
	default:
		return state;
	}
};
